<template>
     <div class="text-xs" >
        <div class="fixed inset-0 z-50 opacity-50 bg-black" style="z-index:110000 !important" ></div>
        <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50" style="z-index:2110000 !important">
            <div class="relative mx-auto w-auto max-w-full">
            <div class="bg-white w-full rounded-xl p-4 overflow-auto max-h-screen">
                <div style="width:24rem">
                    <div class="flex justify-center text-left w-full">
                        <div class="text-left w-full">
                            <div class="w-full text-xs">
                                <div class="flex items-center gap-x-2  border-b-2 pb-2">
                                    <!-- <span class="rounded-full bg-red-50 flex items-center justify-center w-8 h-8">
                                        <unicon name="exclamation-circle"  fill="#DC2626" ></unicon>
                                    </span> -->
                                    <span class="text-base font-medium">Hapus data</span> 

                                </div>
                                <div class="mt-4">
                                    <p class="mt-2 text-red-500 text-lg">Apakah anda yakin untuk menghapus data. Tindakan ini tidak bisa dipulihkan!</p>
                                    <div class="grid grid-cols-2 gap-4 mt-4">
                                        <button
                                        @click="closeModal"
                                        type="submit"
                                        :class="`col-span-1 text-center py-2 rounded
                                    
                                        bg-gray-100
                                        
                                        disabled:opacity-50
                                        focus:outline-none my-1`"


                                    >Batal</button>
                                    <button
                                        @click="deleteUser()"
                                        type="submit"
                                        :class="`col-span-1 text-center py-2 rounded
                                        text-white
                                        merah
                                        disabled:opacity-50
                                        focus:outline-none my-1`"


                                    >Hapus</button>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
    
                </div>

            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
   

  methods: {
    closeModal() {
        this.$emit('closeModal')
    },
    deleteUser() {
        this.$emit('deleteUser')
    }
  },
    computed:{
      
    }

};
</script>

<style>

</style>
